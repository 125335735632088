import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import PhotoStrip from '../components/PhotoStrip'
import FeaturedList from '../components/products/FeaturedList'
import styled from 'styled-components'
import {
  VARIABLE_FUNCTION,
  VW
} from '../styles'
import { LayoutContext } from '../contexts'

const Products = ({
  data: {
    contentfulProductsPage: {
      seoTitle,
      seoDescription,
      seoImage,
      imageTicker,
      listOfFeaturedLists
    }
  }
}) => {
  const { headerBanner } = useContext(LayoutContext)

  return (
    <>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage && seoImage.fluid.src}
        slug=''
      />
      <Products_Wrapper headerBanner={headerBanner}>
        <PhotoStrip images={imageTicker.map((img) => ({
          src: img.resize.src,
          width: img.resize.width,
          height: img.resize.height,
          alt: img.description || ''
        }))} />

      <FeaturedList listOfFeaturedLists={listOfFeaturedLists} />

      </Products_Wrapper>
    </>
  )
}

const Products_Wrapper = styled.div`
  margin-top: ${props => VARIABLE_FUNCTION(props.headerBanner).mobileHeader};

  @media (min-width: ${VW.desktop}) {
    margin-top: ${props => VARIABLE_FUNCTION(props.headerBanner).desktopHeader};
  }
`

export const PageQuery = graphql`
  query Products($id: String!) {
    contentfulProductsPage(id: { eq: $id }) {
      seoTitle
      seoDescription
      seoImage {
        fluid {
          src
        }
      }
      imageTicker {
        description
        resize(height: 512) {
          src
          width
          height
        }
      }
      listOfFeaturedLists {
        intro {
          type
          flavLeft {
            json
          }
          flavRight {
            json
          }
        }
        featuredProducts {
          shopifyProduct {
            handle
            variants {
              edges {
                node {
                  price
                }
              }
            }
          }
          title
          homepageDescription {
            json
          }
          slug
          thumbnailHover {
            title
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          thumbnail {
            title
            fluid(maxWidth: 1600, quality: 80) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

export default Products
