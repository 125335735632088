import React from 'react'
import FeaturedCollection from '../home/FeaturedCollection'

const FeaturedList = ({ listOfFeaturedLists }) => {
  return (
    <>
      {listOfFeaturedLists.map(({
        intro: {
          type,
          flavLeft: {
            json: flavLeft
          },
          flavRight: {
            json: flavRight
          }
        },
        featuredProducts
      }) => (
        <FeaturedCollection
          products={featuredProducts}
          featuredProducts_flavType={type}
          featuredProducts_flavLeft={flavLeft}
          featuredProducts_flavRight={flavRight} />
      ))}
    </>
  )
}

export default FeaturedList